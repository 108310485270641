<template>
  <div class="search-page">
    <h3 class="search-page__title text-center">
      Search Results
    </h3>
    <search-component
      v-if="searchPageData"
      :queryToApply="urlQuery"
      defaultSorting="relevance"
      :bannerAd="searchPageData && searchPageData.bannerAd ? searchPageData.bannerAd : null"
      :showBannerAd="searchPageData ? searchPageData.showBannerAd : true"
      :parentKey="searchPageData ? searchPageData.key : ''"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import PageMixin from '@/mixins/PageMixin'
import ScrollSearchPageByQueryMixin from '@/mixins/ScrollSearchPageByQueryMixin'
import SearchComponent from '@/components/search/SearchComponent.vue'

export default {
  name: 'Search',
  components: {
    SearchComponent
  },
  mixins: [PageMixin, ScrollSearchPageByQueryMixin],
  methods: {
    ...mapActions('search', ['setSearchPageData'])
  },
  computed: {
    ...mapState('search', ['searchPageData']),
    metaData () {
      return this.searchPageData?.meta
    },
    pageTitle () {
      return this.searchPageData?.meta?.title || this.$route.meta?.title
    }
  },
  async created () {
    const { search } = window.location
    const params = new URLSearchParams(search)
    this.urlQuery = params.get('query')

    if (!this.searchPageData) await this.setSearchPageData()

    this.$nextTick(() => {
      this.setPageTitle(this.pageTitle)
      this.setMetaTags()
    })
  },
  beforeDestroy () {
    this.unsetMetaTags()
  },
  data () {
    return {
      urlQuery: ''
    }
  }
}
</script>
